import { defineStore } from 'pinia'
import { workshopApi } from '@/workshop/workshop-api.js'
import { workshopSpecialtyEnums, workshopBaseSpecialtyArray } from '@/workshop/consts/attendee-specialty-options'

const getDefaultUserMgmtFormState = () => {
  return {
    id: null,
    firstName: null,
    lastName: null,
    currentStatus: null,
    primaryPhone: null,
    cellPhone: null,
    email: null,
    hospitalName: null,
    hospitalAddressLine1: null,
    hospitalAddressLine2: null,
    hospitalZipCode: null,
    city: null,
    country: null,
    state: null,
    otherCountry: null,
    medicalLicenseNumber: null,
    medicalLicenseState: null,
    nationalProviderId: null,
    acceptingMeals: null,
    hasDietaryRestrictions: null,
    dietaryRestrictions: null,
    latexAllergy: null,
    selectedSpecialties: [],
    selectedOtherSpecialty: null,
    degrees: [],
    certifications: [],
    credentials: [],
    sendRegistrationEmail: true,
    isInternal: false
  }
}

export const useWorkshopUserMgmtFormStore = defineStore('workshopUserMgmtForm', {
  state: () => ({
    userMgmtForm: getDefaultUserMgmtFormState(),
    isUserMgmtUserEnabled: false,
    isAttendee: false
  }),
  getters: {
    isUserMgmtFormEmpty () {
      const defaultUserMgmtForm = getDefaultUserMgmtFormState()
      return Object.keys(defaultUserMgmtForm).every((key) => {
        if (this.userMgmtForm[key] instanceof Array) {
          return this.userMgmtForm[key].length === defaultUserMgmtForm[key].length
        } else {
          return this.userMgmtForm[key] === defaultUserMgmtForm[key] || this.userMgmtForm[key] === ''
        }
      })
    }
  },
  actions: {
    postFacultyForm () {
      const payload = getFacultyFormPayload(this.userMgmtForm)
      return workshopApi.post('/faculty/create-faculty', payload)
    },
    async getFacultyForm (facultyId) {
      const res = await workshopApi.get(`/workshop-user/${facultyId}`, {
        params: {
          projection: 'detailedFacultyData'
        }
      })

      const facultyInfo = res.data

      this.setParticipantInfo(facultyInfo)
      this.setFacultyOnlyInfo(facultyInfo)
    },
    setParticipantInfo (participantInfo) {
      const userMgmtForm = this.userMgmtForm
      userMgmtForm.id = participantInfo.id
      userMgmtForm.firstName = participantInfo.firstName
      userMgmtForm.lastName = participantInfo.lastName
      userMgmtForm.primaryPhone = participantInfo.primaryPhone
      userMgmtForm.email = participantInfo.email
      userMgmtForm.hospitalName = participantInfo.hospitalName
      userMgmtForm.city = participantInfo.city
      userMgmtForm.isInternal = participantInfo.isInternal
      this.isEnabled = participantInfo.enabled

      if (participantInfo.country === 'USA') {
        userMgmtForm.country = participantInfo.country
        userMgmtForm.state = participantInfo.state
      } else {
        userMgmtForm.country = 'Other'
        userMgmtForm.otherCountry = participantInfo.country
      }

      let specialties = participantInfo.specialties.map((specialtyObj) => specialtyObj.specialty)

      const otherSpecialty = getOtherSpecialty(specialties)
      if (otherSpecialty) {
        userMgmtForm.selectedOtherSpecialty = otherSpecialty
        specialties = specialties.filter((specialty) => specialty !== otherSpecialty)
        specialties.push(workshopSpecialtyEnums.OTHER)
      }

      userMgmtForm.selectedSpecialties = specialties
    },
    setFacultyOnlyInfo (facultyInfo) {
      const degrees = facultyInfo.degrees.map((degreeObj) => degreeObj.degree)
      this.userMgmtForm.degrees = degrees

      const certifications = facultyInfo.certifications.map((certificationObj) => certificationObj.certification)
      this.userMgmtForm.certifications = certifications

      const credentials = facultyInfo.credentials.map((credentialObj) => credentialObj.credential)
      this.userMgmtForm.credentials = credentials
    },
    setAttendeeOnlyInfo (attendeeInfo) {
      const userMgmtForm = this.userMgmtForm
      userMgmtForm.currentStatus = attendeeInfo.currentStatus
      userMgmtForm.cellPhone = attendeeInfo.cellPhone
      userMgmtForm.hospitalAddressLine1 = attendeeInfo.hospitalAddressLine1
      userMgmtForm.hospitalAddressLine2 = attendeeInfo.hospitalAddressLine2
      userMgmtForm.hospitalZipCode = attendeeInfo.hospitalZipCode
      userMgmtForm.medicalLicenseNumber = attendeeInfo.medicalLicenseNumber
      userMgmtForm.medicalLicenseState = attendeeInfo.medicalLicenseState
      userMgmtForm.nationalProviderId = attendeeInfo.nationalProviderId
      userMgmtForm.acceptingMeals = attendeeInfo.acceptingMeals
      userMgmtForm.dietaryRestrictions = attendeeInfo.dietaryRestrictions
      if (attendeeInfo.dietaryRestrictions) {
        userMgmtForm.hasDietaryRestrictions = true
      } else {
        userMgmtForm.hasDietaryRestrictions = false
      }
      userMgmtForm.latexAllergy = attendeeInfo.latexAllergy
    },
    updateFacultyForm () {
      const payload = getFacultyFormPayload(this.userMgmtForm)
      return workshopApi.put('/faculty/update-faculty', payload)
    },
    async getAttendeeForm (attendeeId) {
      const res = await workshopApi.get(`/workshop-user/${attendeeId}`, {
        params: {
          projection: 'detailedAttendeeData'
        }
      })

      const attendeeInfo = res.data

      this.setParticipantInfo(attendeeInfo)
      this.setAttendeeOnlyInfo(attendeeInfo)
      this.isAttendee = true
    },
    updateAttendeeForm () {
      const payload = getAttendeeFormPayload(this.userMgmtForm)
      return workshopApi.put('/admin-attendee/update-attendee', payload)
    },
    async getFacultyAttendeeForm (userId) {
      const res = await workshopApi.get(`/workshop-user/${userId}`, {
        params: {
          projection: 'detailedFacultyAttendeeData'
        }
      })

      const facultyAttendeeInfo = res.data

      this.setParticipantInfo(facultyAttendeeInfo)
      this.setAttendeeOnlyInfo(facultyAttendeeInfo)
      this.setFacultyOnlyInfo(facultyAttendeeInfo)
      this.isAttendee = true
    },
    updateFacultyAttendeeForm () {
      const payload = getFacultyAttendeeFormPayload(this.userMgmtForm)
      return workshopApi.put('/admin-participant/update-faculty-attendee', payload)
    },
    setFirstName (firstName) {
      this.userMgmtForm.firstName = firstName
    },
    setLastName (lastName) {
      this.userMgmtForm.lastName = lastName
    },
    setCurrentStatus (currentStatus) {
      this.userMgmtForm.currentStatus = currentStatus
    },
    setPrimaryPhone (primaryPhone) {
      this.userMgmtForm.primaryPhone = primaryPhone
    },
    setCellPhone (cellPhone) {
      this.userMgmtForm.cellPhone = cellPhone
    },
    setEmail (email) {
      this.userMgmtForm.email = email
    },
    setHospitalName (hospitalName) {
      this.userMgmtForm.hospitalName = hospitalName
    },
    setHospitalAddressLine1 (hospitalAddressLine1) {
      this.userMgmtForm.hospitalAddressLine1 = hospitalAddressLine1
    },
    setHospitalAddressLine2 (hospitalAddressLine2) {
      this.userMgmtForm.hospitalAddressLine2 = hospitalAddressLine2
    },
    setHospitalZipCode (hospitalZipCode) {
      this.userMgmtForm.hospitalZipCode = hospitalZipCode
    },
    setHospitalCity (hospitalCity) {
      this.userMgmtForm.city = hospitalCity
    },
    setHospitalCountry (hospitalCountry) {
      this.userMgmtForm.country = hospitalCountry
    },
    setHospitalState (hospitalState) {
      this.userMgmtForm.state = hospitalState
    },
    setOtherCountry (otherCountry) {
      this.userMgmtForm.otherCountry = otherCountry
    },
    setMedicalLicenseNumber (medicalLicenseNumber) {
      this.userMgmtForm.medicalLicenseNumber = medicalLicenseNumber
    },
    setMedicalLicenseState (medicalLicenseState) {
      this.userMgmtForm.medicalLicenseState = medicalLicenseState
    },
    setNationalProviderId (nationalProviderId) {
      this.userMgmtForm.nationalProviderId = nationalProviderId
    },
    setSelectedSpecialties (selectedSpecialties) {
      this.userMgmtForm.selectedSpecialties = selectedSpecialties
    },
    setDegrees (degrees) {
      this.userMgmtForm.degrees = degrees
    },
    setCertifications (certifications) {
      this.userMgmtForm.certifications = certifications
    },
    setCredentials (credentials) {
      this.userMgmtForm.credentials = credentials
    },
    setAcceptingMeals (acceptingMeals) {
      this.userMgmtForm.acceptingMeals = acceptingMeals
    },
    setHasDietaryRestrictions (hasDietaryRestrictions) {
      this.userMgmtForm.hasDietaryRestrictions = hasDietaryRestrictions
    },
    setDietaryRestriction (dietaryRestriction) {
      this.userMgmtForm.dietaryRestrictions = dietaryRestriction
    },
    setLatexAllergy (latexAllergy) {
      this.userMgmtForm.latexAllergy = latexAllergy
    },
    setSendRegistrationEmail (sendRegistrationEmail) {
      this.userMgmtForm.sendRegistrationEmail = sendRegistrationEmail
    },
    setIsInternal (isInternal) {
      this.userMgmtForm.isInternal = isInternal
    },
    setOtherSpecialty (otherSpecialty) {
      this.userMgmtForm.selectedOtherSpecialty = otherSpecialty
    }
  }
})

function getFacultyFormPayload (facultyForm) {
  return {
    ...getParticipantFormPayload(facultyForm),
    degrees: facultyForm.degrees,
    certifications: facultyForm.certifications,
    credentials: facultyForm.credentials
  }
}

function getAttendeeFormPayload (attendeeForm) {
  return {
    ...getParticipantFormPayload(attendeeForm),
    currentStatus: attendeeForm.currentStatus,
    cellPhone: attendeeForm.cellPhone,
    hospitalAddressLine1: attendeeForm.hospitalAddressLine1,
    hospitalAddressLine2: attendeeForm.hospitalAddressLine2,
    hospitalZipCode: attendeeForm.hospitalZipCode,
    medicalLicenseNumber: attendeeForm.medicalLicenseNumber,
    medicalLicenseState: attendeeForm.medicalLicenseState,
    nationalProviderId: attendeeForm.nationalProviderId,
    acceptingMeals: attendeeForm.acceptingMeals,
    dietaryRestrictions: attendeeForm.hasDietaryRestrictions ? attendeeForm.dietaryRestrictions : '',
    latexAllergy: attendeeForm.latexAllergy
  }
}

function getParticipantFormPayload (participantForm) {
  return {
    id: participantForm.id,
    firstName: participantForm.firstName,
    lastName: participantForm.lastName,
    email: participantForm.email,
    phone: participantForm.primaryPhone,
    hospitalName: participantForm.hospitalName,
    city: participantForm.city,
    state: participantForm.state,
    country: participantForm.country === 'Other' ? participantForm.otherCountry : participantForm.country,
    specialty: getSpecialtyPayload(participantForm.selectedSpecialties, participantForm.selectedOtherSpecialty),
    sendRegistrationEmail: participantForm.sendRegistrationEmail,
    isInternal: participantForm.isInternal
  }
}

function getFacultyAttendeeFormPayload (facultyAttendeeForm) {
  return {
    ...getAttendeeFormPayload(facultyAttendeeForm),
    degrees: facultyAttendeeForm.degrees,
    certifications: facultyAttendeeForm.certifications,
    credentials: facultyAttendeeForm.credentials
  }
}

function getSpecialtyPayload (selectedSpecialties, selectedOtherSpecialty) {
  const hasOtherSpecialty = selectedSpecialties.includes(workshopSpecialtyEnums.OTHER)
  if (hasOtherSpecialty) {
    const specialityWithoutOther = selectedSpecialties.filter((specialty) => specialty !== workshopSpecialtyEnums.OTHER)
    specialityWithoutOther.push(selectedOtherSpecialty)
    return specialityWithoutOther
  }

  return selectedSpecialties
}

function getOtherSpecialty (specialties) {
  const otherSpecialty = specialties.find((specialty) => !workshopBaseSpecialtyArray.includes(specialty))
  return otherSpecialty
}
