const generalEndpoints = Object.freeze({
  RESIDENCY_PREFIX: 'residency',

  // General
  LOGIN: 'login',
  LOGOUT: 'logout',
  REGISTER: 'register',
  HOME: 'home',
  ASMT_HISTORY: 'assessment-history',
  HISTORY: 'history',

  // AR cam nav
  AR_CAM_NAV: 'virtual-camera-navigation',

  // Lesson Plan
  LESSON_PLAN: 'lesson-plan',

  // Assessments
  ASSESSMENT: 'assessment',
  TASK_CHECKLIST: 'task-checklist',
  GRS: 'grs',
  DEBRIEF: 'debrief',
  REFLECTION: 'reflection',
  COGNITIVE_ASSESSMENT: 'cognitive-assessment',

  // Admin
  ADMIN_SYSTEM: 'admin/system',
  ADMIN_HOME: 'admin/home',
  ADMIN_INTERNAL_ACCOUNTS: 'admin/internal-accounts',
  ADMIN_VIDEO_OVERVIEW: 'admin/video-overview',
  ADMIN_COURSES: 'admin/courses',
  ADMIN_CALENDAR: 'admin/calendar',
  ADMIN_SYSTEM_DETAILS: 'admin/system-details',
  ADMIN_PROGRAM_DETAILS: 'admin/program-details',
  ADMIN_CREATE_PROGRAM: 'admin/create-program',
  ADMIN_INACTIVE_PROGRAMS: 'admin/inactive-programs',
  ADMIN_CREATE_SYSTEM: 'admin/create-system',
  ADMIN_MANAGE_USERS: 'admin/manage-users',
  ADMIN_CREATE_USER_GROUP: 'admin/create-user-group',
  ADMIN_COURSE_PROGRESS: 'admin/course-progress',
  ADMIN_RESIDENTS: 'residents',
  ADMIN_FACULTY: 'faculty',
  ADMIN_ACTIVE_GROUPS: 'active-groups',
  ADMIN_UNREGISTERED_USERS: 'unregistered-users',
  ADMIN_INACTIVE_USERS: 'inactive-users',
  ADMIN_EXPIRED_GROUPS: 'expired-groups',
  ADMIN_EDIT_PROGRAM: 'admin/edit-program',
  ADMIN_VIEW_PROGRAM: 'admin/view-program',
  ADMIN_EDIT_GROUPS: 'admin/edit-groups',
  ADMIN_ASMT_HISTORY: 'admin/assessment-history',
  ADMIN_RESIDENT_PROGRESS: 'admin/resident-progress',
  ADMIN_MASTER_SCHEDULE: 'admin/master-schedule',
  ADMIN_MOBILE_APP_RELEASE: 'admin/mobile-app-release',
  ADMIN_IOS: 'ios',
  ADMIN_ANDROID: 'android',

  // Program Director
  MANAGE_PROGRAM: 'manage/program',

  // Account
  ACCOUNT: 'account',
  PROFILE: 'profile',
  MEMBERS: 'members',
  CURRICULAR_HISTORY: 'curricular-history',

  // Notifications
  MOBILE_NOTIFICATIONS: 'notifs',

  // Legal
  PRIVACY_POLICY: 'privacy-policy',
  LEGAL_NOTICE: 'legal-notice',
  TRADEMARKS: 'trademarks',
  DPA: 'data-processing-addendum',

  // Course
  ALL_COURSES: 'courses',
  COURSE_PAGE: 'course',
  ACTIVITY_GUIDE: 'activity-guide',
  OUTLINE: 'outline',
  DIDACTIC: 'didactic',
  TECHNICAL: 'technical',
  ACTIVITY: 'activity',

  // Video Asmts
  VIDEO_LIBRARY: 'video-library',
  MY_VIDEOS: 'my-videos',
  PEER_VIDEOS: 'peer-videos',
  VIDEO_TECHNICAL_ASMT: 'video-assessment',
  RECORD: 'record',
  VIDEO_RECORD: 'video-record',
  FACULTY_VIDEO_RECORD: 'faculty-video-record',
  UPLOAD: 'upload',
  VIDEO_UPLOAD: 'video-upload',
  FACULTY_VIDEO_UPLOAD: 'faculty-video-upload',
  SHARE_VIDEO: 'share-video',

  // Video Library types and categories
  TO_ASSESS: 'to-assess',
  VIDEO_QUEUE: 'video-queue',
  SHARED_VIDEOS: 'shared-videos',
  SIMULATION: 'simulation',
  SURGICAL: 'surgical',
  OTHER: 'other',
  ASSESSED_VIDEOS: 'assessed-videos',
  ALL: 'all',
  SAVED: 'saved',
  SAVED_VIDEOS: 'saved-videos',
  NEW_VIDEO: 'new-video',
  RESIDENT_SHARED_LIBRARY: 'resident-shared-library',
  FACULTY_SHARED_LIBRARY: 'faculty-shared-library',

  // Assessment History
  TECHNICAL_ASSESSMENTS: 'technical-assessments',
  COGNITIVE_ASSESSMENTS: 'cognitive-assessments',
  METRICS: 'metrics',
  FOCUS: 'focus',
  FOLLOW: 'follow',
  TRACE: 'trace',
  STAR: 'star',
  TUBE: 'tube'
})

// Need to use the outdated `module.exports` below because we need
// `generalEndpoints` in `vue.config.js`, which doesn't support `import`
module.exports = generalEndpoints
