import { createApp } from 'vue'
import MsgBoxOk from '@/components/alert/MsgBoxOk'
import MsgBoxConfirm from '@/components/alert/MsgBoxConfirm'
import mitt from 'mitt'
import vuetify from '@/plugins/vuetify'

/**
 * Base alert function to trigger our customly styled alert types.
 * Called by `this.$baseAlert(props).msgBoxOk(props)` (or similar for msgBoxconfirm),
 * set to the Vue prototype in main.js, giving it global scope for all components.
 * *
 * @param {Object} props               The properties which to pass to `BaseAlertModal`. These
 *                                     are all optional properties, and are described below
 * @param {String} props.title         The alert title
 * @param {String} props.text          The body of the text which to pass to `BaseAlertModal`
 * @param {Object} props.textStyle     Custom styling for the body text
 * @param {String} props.alertType     The alert type, defined by alert-types.js. These
 *                                     describe the alert icon in the modal
 * @param {Object} props.vDialogProps: v-dialog properties to overwrite the default props
 *                                     set in BaseModalAlert. See vuetify docs for details
 * @param {String} props.confirmText:  The text to put on the confirm/ok button
 * @param {String} props.cancelText:   The text to put on the cancel button. Not used in
 *                                     msgBoxOk because it doesn't have a cancel button
 * @param {Number} props.width:        The width in px size to which to set the alert modal
 * @param {Number} props.imgSrc:       The image in the top center of the alert modal
 *                                     Image path already starts from '@/assets/icons/'
 * @param {Object} props.confirmStyle: Customize styling for the confirm button
 * @param {Object} props.cancelStyle:  Customize styling for the cancel button
 * @param {Object} router:             The vue router object
 */
export default function ({
  title, titleStyle, text, textStyle, alertType, vDialogProps, confirmText,
  cancelText, width, imgSrc, cancelStyle, confirmStyle
} = {}, router) {
  const msgBoxProps = {
    text,
    textStyle,
    title,
    titleStyle,
    alertType,
    vDialogProps,
    confirmText,
    cancelText,
    width,
    imgSrc,
    cancelStyle,
    confirmStyle
  }

  return {
    msgBoxOk: () => {
      return createMsgBox(MsgBoxOk, msgBoxProps, router)
    },
    msgBoxConfirm: () => {
      return createMsgBox(MsgBoxConfirm, msgBoxProps, router)
    }
  }
}

export let alertModal

// Create instance of AlertModal component
const createMsgBox = (component, props, router) => {
  if (alertModal) {
    // only allow one instance of an alert modal at a time
    alertModal.unmount()
  }

  const emitter = mitt()

  alertModal = createApp(
    component,
    {
      ...props,
      emitter
    }
  )
  alertModal.use(vuetify)
  alertModal.use(router)

  // Resolve to either `true` for confirmed or `false` for canceled events
  return new Promise((resolve, reject) => {
    emitter.on('confirm', () => {
      resolve(true)
    })
    emitter.on('cancel', () => {
      resolve(false)
    })

    // Mount our alert modal to the root <body> element
    let div = document.getElementById('base-alert')
    if (!div) {
      div = document.createElement('div')
      div.setAttribute('id', 'base-alert')
    }
    document.body.appendChild(div)
    alertModal.mount(div)
  })
}
